import React from 'react';
import UserContext from './UserContext';
import SignIn from './modules/auth/SignIn';
import User from './models/User';
import AccessDenied from './modules/errors/AccessDenied';
import Lobby from './modules/auth/Register';
import UserIdContext from './UserIdContext';

export default function AuthBoundary({
  allowed,
  children,
}: {
  allowed: Array<User['role']>;
  children: React.ReactChild;
}) {
  const uid = React.useContext(UserIdContext);
  const user = React.useContext(UserContext);

  if (!uid || !user) {
    return <SignIn />;
  }
  if (!user.role) {
    return <Lobby />;
  }
  if (!allowed.find((f) => user.role === f)) {
    return <AccessDenied />;
  }
  return <>{children}</>;
}
