import React from 'react';
import moment from 'moment';

interface TimerProps {
  started: number;
}

export default function Timer(props: TimerProps) {
  const [tick, setTick] = React.useState(0);
  const { started } = props;
  const diff = moment().diff(moment.unix(started), 'seconds');

  React.useEffect(() => {
    const timer = setInterval(() => {
      setTick((prevCount) => prevCount + 1);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div>
      <span style={{ display: 'none' }}>{tick}</span>
      {moment().startOf('day').seconds(diff).format('H:mm:ss')}
    </div>
  );
}
