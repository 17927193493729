import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      background:
        'linear-gradient(180deg, rgba(255,0,0,1) 41%, rgba(0,129,255,1) 100%)',
    },
  })
);

const AccessDenied = () => {
  const classes = useStyles();

  return (
    <div className={classes.background}>
      <Container>
        <h3>AccessDenied</h3>
      </Container>
    </div>
  );
};

export default AccessDenied;
