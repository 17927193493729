import React from 'react';
import firebase from 'firebase/app';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Box, Button, Container, Grid } from '@material-ui/core';
import GoogleLogo from '../../icons/GoogleLogo';

export async function EmailLogin(email: string, password: string) {
  try {
    await firebase.auth().signInWithEmailAndPassword(email, password);
  } catch (error) {
    throw new Error(error);
  }
}

export async function SocialLogin(LoginType: string) {
  firebase.auth();
  let provider: any;

  // if (LoginType === 'facebook') {
  //   provider = new firebase.auth.FacebookAuthProvider();
  // }
  if (LoginType === 'google') {
    provider = new firebase.auth.GoogleAuthProvider();
  }
  try {
    const result = await firebase.auth().signInWithPopup(provider);
    if (result.additionalUserInfo?.isNewUser) {
      /** @todo */
      console.log(result.user);
      // await setUserProfileData(result.user);
    }
  } catch (error) {
    throw new Error(error);
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnFacebook: {
      backgroundColor: '#3b5998',
      color: 'white',
    },
    btnGoogle: {
      padding: theme.spacing(3),
      backgroundColor: 'white',
      color: 'black',
    },
  })
);

const SignIn = () => {
  const classes = useStyles();

  return (
    <Box p={4}>
      <Container maxWidth="xs">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Button
              className={classes.btnGoogle}
              variant="contained"
              fullWidth
              startIcon={<GoogleLogo />}
              onClick={() => {
                SocialLogin('google');
              }}
            >
              Sign In With Google
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SignIn;
