import { lime } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  shape: {
    borderRadius: 6,
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    button: {
      // textTransform: 'none',
      // fontWeight: 600,
      // fontSize: 18
    },
    h1: {
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: 38,
    },
    h2: {
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: 28,
    },
    h3: {
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: 26,
    },
    h4: {
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: 24,
    },
    h5: {
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: 23,
    },
    h6: {
      fontWeight: 500,
      fontStyle: 'normal',
    },
    subtitle1: {
      fontWeight: 700,
      fontStyle: 'normal',
      fontSize: 25,
    },
    subtitle2: {
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: 21,
    },
    body1: {
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: 17,
      color: '#FFF',
    },
  },
  palette: {
    background: {
      default: '#303135',
    },
    primary: {
      main: lime.A400,
      contrastText: '#FFF',
    },
    secondary: {
      main: '#c62b44',
      contrastText: '#f0fff9',
    },
  },
});
