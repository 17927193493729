import React from 'react';
import firebase from 'firebase/app';
import { Box, IconButton, Paper, Toolbar } from '@material-ui/core';
import PlusIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import Timer from './Timer';
import Project from '../../models/Project';
import Collection from '../../models/Collection';
import ProjectContext from '../../ProjectContext';
import UserIdContext from '../../UserIdContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      perspective: '800px',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    row: {
      flexGrow: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(1),
    },
    col: {
      position: 'relative',
      width: '100%',
      cursor: 'pointer',
      flexGrow: 1,
    },
    front: {
      fontWeight: 700,
      color: 'rgba(255,255,255,0.6)',
      transform: 'translate3d(0,0,0)',
      '-webkit-transform-style': 'preserve-3d',
      '-moz-transform-style': 'preserve-3d',
      '-ms-transform-style': 'preserve-3d',
      'transform-style': 'preserve-3d',
      transition: '400ms cubic-bezier(.47,1.64,.41,.8)',
      '-webkit-backface-visibility': 'hidden' /* Safari */,
      'backface-visibility': 'hidden',
      overflow: 'hidden',
      background: '#303135',
      padding: theme.spacing(0, 3),
      textOverflow: 'ellipsis',
      width: '100%',
      height: '100%',
      position: 'absolute',
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: theme.shape.borderRadius,
      '&:hover': {
        background: '#505155',
      },
    },
    frontActive: {
      transform: 'rotateX(180deg)',
    },
    back: {
      color: 'rgba(0,0,0,0.7)',
      transform: 'rotateX(180deg)',
      background: theme.palette.primary.main,
      boxShadow:
        'inset .3em .3em .3em 0 rgba(250,255,250,0.2), inset -.3em -.3em .3em 0 rgba(0,0,0,0.1)',
      '&:hover': {
        background: theme.palette.primary.light,
      },
    },
    backActive: {
      transform: 'rotateX(0deg)',
    },
    time: {
      padding: theme.spacing(0, 1),
      display: 'flex',
      textAlign: 'right',
      alignItems: 'center',
    },
    title: {
      fontSize: '1.6rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'flex',
      alignItems: 'center',
    },
  })
);

export default function GridView() {
  const classes = useStyles();
  const uid = React.useContext(UserIdContext);
  const projects = React.useContext(ProjectContext);

  function getRef() {
    const userTable: Collection = 'users';
    const projectsTable: Collection = 'projects';
    return firebase
      .firestore()
      .collection(userTable)
      .doc(uid || '')
      .collection(projectsTable);
  }

  function handleStart(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    const id = event.currentTarget.getAttribute('data-id');
    if (!id) {
      return;
    }
    console.log(uid, id);

    const update: Pick<Project, 'started'> = {
      started: moment().unix(),
    };
    getRef().doc(id).update(update);
  }

  function handleStop(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    const id = event.currentTarget.getAttribute('data-id');
    if (!id) {
      return;
    }
    const update: Pick<Project, 'started'> = {
      started: null,
    };
    getRef().doc(id).update(update);
  }

  return (
    <Box className={classes.root}>
      {projects.map((p) => (
        <Box key={p.id} className={classes.row}>
          <Box className={classes.col}>
            <Paper
              elevation={6}
              data-id={p.id}
              onClick={handleStart}
              className={clsx(classes.front, {
                [classes.frontActive]: p.started,
              })}
            >
              <Box className={classes.title}>{p.name}</Box>
              <Box className={classes.time}>
                {p.secs &&
                  moment().startOf('day').seconds(p.secs).format('H:mm:ss')}
              </Box>
            </Paper>

            <Paper
              elevation={8}
              data-id={p.id}
              onClick={handleStop}
              className={clsx(classes.front, classes.back, {
                [classes.backActive]: p.started,
              })}
            >
              <Box className={classes.title}>{p.name}</Box>
              <Box className={classes.time}>
                {p.started && <Timer started={p.started} />}
              </Box>
            </Paper>
          </Box>
        </Box>
      ))}
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <IconButton color="primary">
          <PlusIcon />
        </IconButton>
        <IconButton color="primary">
          <EditIcon />
        </IconButton>
      </Toolbar>
    </Box>
  );
}
