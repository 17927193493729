import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import AuthBoundary from './AuthBoundary';
import UserProvider from './UserProvider';
import Grid from './modules/grid/Grid';
import ProjectProvider from './ProjectProvider';

function App() {
  return (
    <BrowserRouter>
      <UserProvider>
        <AuthBoundary allowed={['admin']}>
          <ProjectProvider>
            <Switch>
              <Route>
                <Grid />
              </Route>
            </Switch>
          </ProjectProvider>
        </AuthBoundary>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
