import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCAE_e054yZxWBZBCPOQ79s1wyEiBWzP14",
  authDomain: "getclokkin.firebaseapp.com",
  databaseURL: "https://getclokkin.firebaseio.com",
  projectId: "getclokkin",
  storageBucket: "getclokkin.appspot.com",
  messagingSenderId: "339755818083",
  appId: "1:339755818083:web:43f726568e7f570727ac56",
  measurementId: "G-X96NYTVX59"
};

const app = firebase.initializeApp(firebaseConfig);

export default app;
