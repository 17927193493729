import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      padding: theme.spacing(1),
      height: '100vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

const Loading = () => {
  const classes = useStyles();

  return (
    <Box className={classes.backdrop}>
      <CircularProgress />
    </Box>
  );
};

export default Loading;
