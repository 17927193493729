import React from 'react';
import moment from 'moment';
import firebase from 'firebase/app';
import { Button, Grid, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnFacebook: {
      backgroundColor: '#3b5998',
      color: 'white',
    },
    btnGoogle: {
      backgroundColor: 'white',
      color: 'black',
    },
    gridItem: {
      padding: '10px',
    },
  })
);

async function setUserProfileData(user: any) {
  try {
    const db = firebase.firestore();
    return await db.collection('users').doc(user.uid).set({
      displayName: user.displayName,
      email: user.email,
      createdAt: moment().unix(),
    });
  } catch (error) {
    throw new Error(error);
  }
}

async function RegisterUser(name: string, email: string, password: string) {
  try {
    const result = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    await result.user?.updateProfile({
      displayName: name,
    });
    return await setUserProfileData(result.user);
  } catch (error) {
    throw new Error(error);
  }
}

const Register = () => {
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  async function onSubmit(e: any) {
    try {
      return await RegisterUser(e.name, e.email, e.password);
    } catch (error) {
      throw new Error(error);
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={12} sm={12} className={classes.gridItem}>
          <TextField
            name="name"
            label="Full Name"
            variant="outlined"
            fullWidth
            inputRef={register}
          />
        </Grid>
        <Grid item xs={12} sm={12} className={classes.gridItem}>
          <TextField
            name="email"
            label="Email"
            variant="outlined"
            fullWidth
            inputRef={register}
          />
        </Grid>
        <Grid item xs={12} sm={12} className={classes.gridItem}>
          <TextField
            name="password"
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            inputRef={register}
          />
        </Grid>
        <Grid item xs={12} sm={12} className={classes.gridItem}>
          <Button variant="contained" fullWidth color="primary" type="submit">
            Sign Up
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Register;
