import React from 'react';
import defaultProject from './models/ProjectDefault';
import firebase from 'firebase/app';
import { useCollection } from 'react-firebase-hooks/firestore';
import Project from './models/Project';
import { Box, Button } from '@material-ui/core';
import Collection from './models/Collection';
import UserIdContext from './UserIdContext';
import ProjectContext from './ProjectContext';
import Loading from './components/Loading';

export default function ProjectProvider({
  children,
}: {
  children: React.ReactChild;
}) {
  const db = firebase.firestore();
  const uid = React.useContext(UserIdContext);
  const userTable: Collection = 'users';
  const projectTable: Collection = 'projects';
  const query = db
    .collection(userTable)
    .doc(uid || '')
    .collection(projectTable);
  const [snapshot, loading, error] = useCollection(query);

  function createData() {
    [
      'OcuPlan',
      'CaddySoft',
      'Whisperer',
      'Global Consent',
      'Clokkin',
      'Touchpanel',
      'Other',
    ].forEach((k) => {
      const newDoc = query.doc();
      const project: Project = {
        ...defaultProject,
        id: newDoc.id,
        name: k,
      };
      newDoc.set(project, { merge: true });
    });
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <Box
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {error.message}
      </Box>
    );
  }

  if (snapshot.empty) {
    return (
      <Box
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button variant="contained" onClick={createData}>
          Create Projects
        </Button>
      </Box>
    );
  }

  return (
    <ProjectContext.Provider
      value={snapshot.docs.map((d: firebase.firestore.DocumentSnapshot) =>
        d.data()
      )}
    >
      {children}
    </ProjectContext.Provider>
  );
}
