import React from 'react';
import firebase from 'firebase/app';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDocument } from 'react-firebase-hooks/firestore';
import UserContext from './UserContext';
import User from './models/User';
import defaultUser from './models/UserDefault';
import Collection from './models/Collection';
import UserIdContext from './UserIdContext';
import Loading from './components/Loading';

//---------------------------------------------------------
function UserProvider({
  uid,
  children,
}: {
  uid: string;
  children: React.ReactChild;
}) {
  const table: Collection = 'users';
  const query = firebase.firestore().collection(table).doc(uid);
  const [doc, loading, error] = useDocument(query);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>{error.message}</p>;
  }
  /** @todo @youarehere make user profile */
  // if (!doc || !doc.exists) {
  //   return (
  //     <UserContext.Provider value={defaultUser}>setup</UserContext.Provider>
  //   );
  // }

  return (
    <UserContext.Provider
      value={{
        ...defaultUser,
        ...(doc.data() as User),
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
const auth = firebase.auth();
//---------------------------------------------------------
export default function AuthBoundary({
  children,
}: {
  children: React.ReactChild;
}): React.ReactElement {
  const [user, loading, error] = useAuthState(auth);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>We could not sign you in at this time.</p>;
  }
  if (!user || !user.uid) {
    return (
      <UserIdContext.Provider value={null}>
        <UserContext.Provider value={null}>{children}</UserContext.Provider>
      </UserIdContext.Provider>
    );
  }

  return (
    <UserIdContext.Provider value={user.uid}>
      <UserProvider uid={user.uid}>{children}</UserProvider>
    </UserIdContext.Provider>
  );
}
